import React from 'react';

class Carousel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      count: 0,
      seats: this.getSeats(props.children),
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.handleUpdate, 2500),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      this.setState({ seats: this.getSeats(nextProps.children) });
      return true;
    }

    if (nextState.activeIndex !== this.state.activeIndex) {
      return true;
    }

    if (nextState.count < 0) {
      this.setState({
        activeIndex: (this.state.activeIndex + 1) % this.state.seats,
        count: 2,
      });
    }

    return false;
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  handleUpdate = () => {
    this.setState({ count: this.state.count - 1 });
  }

  getSeats = (children) => {
    if (!children) {
      return 0;
    } else if (typeof(children.length) !== 'number') {
      return 1;
    }

    return children.length;
  }

  setActive = (index) => {
    this.setState({ activeIndex: index, count: 4 });
  }

  render() {
    const { activeIndex, seats } = this.state;

    return (
      <section className="carousel">
        <div
          className="carousel-items"
          style={{transform: `translateX(${activeIndex * -100}%)`}}
        >
          {this.props.children}
        </div>
        <div className="carousel-nav">
          {[...Array(seats).keys()].map((i) => (
            <button key={i} aria-label={`Slide #${i}`}
              onClick={() => { this.setActive(i); }}
              className={i === activeIndex ? 'active' : ''} />
          ))}
        </div>
      </section>
    );
  }

}

export default Carousel;
