import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faChartPie, faCity, faGamepad, faHandshake, faLaptopCode,
  faMoneyBillWave, faShoppingCart, faUsers
  } from '@fortawesome/free-solid-svg-icons';
import 'assets/scss/komsek.scss';

import SEO from 'components/seo';
import Header from 'components/header';
import Carousel from 'components/carousel';
import Footer from 'components/footer';

import CostsImage from 'assets/images/costs.svg';
import EfficacyImage from 'assets/images/efficacy.svg';
import StandardizationImage from 'assets/images/standardization.svg';
import ResourcesImage from 'assets/images/resources.svg';

export default () => (
  <>
    <SEO />
    <Header expanded />
    <article>
      <Carousel>
        <div className="text-center hero">
          <div className="container grid-sm" style={{ marginBottom: '6rem' }}>
            <h2>
              Vítejte na webových stránkách servisní organizace{' '}
              <span className="text-primary" style={{fontSize: '1.2em'}}>KOMSEK</span>
            </h2>
            <p>Pomůžeme Vám udělat věci snazší a ušetřit!</p>
          </div>
        </div>
        <div>
          <div className="container grid-lg">
            <h2>Čeho chceme dosáhnout?</h2>
            <div className="columns" style={{ justifyContent: 'center'}}>
              <div className="column col-3 col-sm-5 text-center">
                <img src={CostsImage} alt="Ilustrace k snížení nákladů" className="mx-3" />
                <p>Snížení nákladů</p>
              </div>
              <div className="column col-3 col-sm-5 text-center">
                <img src={EfficacyImage} alt="Ilustrace k zvýšení efektivity" className="mx-3" />
                <p>Zvýšení efektivity</p>
              </div>
              <div className="column col-3 col-sm-5 text-center">
                <img src={StandardizationImage} alt="Ilustrace k standardizaci" className="mx-3" />
                <p>Zavedení standardizace</p>
              </div>
              <div className="column col-3 col-sm-5 text-center">
                <img src={ResourcesImage} alt="Ilustrace k sdílení zdrojů" className="mx-3" />
                <p>Sdílení zdrojů</p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>

      <section className="blocks">
        <div className="container grid-xl">
          <div className="columns">
            <Link
              to='/oblasti/' state={{ field: 'economy' }}
              className="col-3 col-sm-6 economy"
            >
              <FontAwesomeIcon icon={faMoneyBillWave} />
              <h6>Ekonomika</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'controlling' }}
              className="col-3 col-sm-6 controlling"
            >
              <FontAwesomeIcon icon={faGamepad} />
              <h6>Controlling</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'analysis' }}
              className="col-3 col-sm-6 analysis"
            >
              <FontAwesomeIcon icon={faChartPie} />
              <h6>Analýza ZP</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'management' }}
              className="col-3 col-sm-6 management"
            >
              <FontAwesomeIcon icon={faCity} />
              <h6>Facility management</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'it' }}
              className="col-3 col-sm-6 it"
            >
              <FontAwesomeIcon icon={faLaptopCode} />
              <h6>IT a&nbsp;aplikační podpora</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'personnel' }}
              className="col-3 col-sm-6 personnel"
            >
              <FontAwesomeIcon icon={faUsers} />
              <h6>Mzdy a&nbsp;personalistika</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'marketing' }}
              className="col-3 col-sm-6 marketing"
            >
              <FontAwesomeIcon icon={faBullhorn} />
              <h6>Marketing a&nbsp;podpora prodeje</h6>
            </Link>
            <Link
              to='/oblasti/' state={{ field: 'buy' }}
              className="col-3 col-sm-6 buy"
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              <h6>Nákup a&nbsp;zbožíznalství</h6>
            </Link>
          </div>
        </div>
      </section>

      <section id="proc-pracovat-s-nami">
        <div className="container grid-lg">
          <h2>
            <FontAwesomeIcon icon={faHandshake} /><br/>
            Proč pracovat s&nbsp;námi
          </h2>

          <div className="columns col-gapless">
            <div className="column col-6 col-sm-12">
              <ul className="my-0">
                <li>snížení a kontrola nákladů</li>
                <li>soustředění na hlavní činnost podniku</li>
                <li>přístup k možnostem a schopnostem na vysoké úrovni</li>
                <li>nemusíte mít všechny zdroje a činnosti dostupné interně</li>
                <li>rychlé řešení nových požadavků na funkční oblast</li>
                <li>zvýšení pružnosti zdrojů</li>
              </ul>
            </div>
            <div className="column col-6 col-sm-12">
              <ul className="my-0">
                <li>standardizace řešení předmětné oblasti zajištěním jednoho poskytovatele pro více společností</li>
                <li>organizační důvody</li>
                <li>sdílení nákladů na ošetření rizik</li>
                <li>uvolnění kapitálových prostředků a vyrovnání nákladů v čase</li>
                <li>některé činnosti jsou těžko zvladatelné, nebo zcela mimo kontrolu</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </article>
    <Footer />
  </>
);
